'use strict';

// stylesheet import
import 'swiper/css/bundle';
import '../sass/main.sass';
// import '../scss/main.scss';

import Swiper from 'swiper/bundle';
import { createApp } from 'vue';
import { store } from './store.js';
import App from './vue/app.vue';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
import { IsLegacyIE } from './modules/checking-legacy-ie.js';
import { FindOS } from './modules/checking-os.js';
import { FindBrowser } from './modules/checking-browser.js';
const $ = require('jQuery');

// Vue.js
// const app = createApp({});
// app.component('app', App)
//   .use(store)
//   .mount('#app');

// Swiper
const swiper = new Swiper('.js-slider', {
  loop: true,
  effect: 'fade',
  speed: 1000,
  slidesPerView: 1,
  fadeEffect: {
    crossFade: true
  },
  autoplay: {
    delay: 2000,
  },
  pagination: {
    el: '.swiper-pagination',
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
  

// jQuery
$(function() {
  
  // image gallery
  $('.js-image-gallery--thumbnails').find('img').each(function() {
    $(this).on('click', function() {
      const targetSrc = $(this).attr('src');
      $('.js-image-gallery--image').find('img').attr('src', targetSrc);
    });
  });

});

// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();